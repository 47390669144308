import React, { ReactElement } from 'react';
import { SPACING } from '../../styles';
import { LinkWrapper } from '../atoms';
import { Grid } from '../grid';
import { Headline, LinkGridListWrapper, ListItemBox, ListItemWrapper } from './linkGridList-styles';

type LinkGridListItem = {
  component: ReactElement;
  title: string;
  url: string;
};

export interface LinkGridListProps {
  headline: string;
  listItems: LinkGridListItem[];
}

export const LinkGridList = ({ headline, listItems, ...props }: LinkGridListProps): ReactElement => {
  return (
    <LinkGridListWrapper {...props}>
      {headline && <Headline>{headline}</Headline>}
      <Grid
        colspan={[2, 12]}
        gutterY={[SPACING.three + `px`, '24px']}
        gutterX={[SPACING.three + `px`, SPACING.three + `px`, SPACING.four + `px`]}
      >
        {gridItems(listItems)}
      </Grid>
    </LinkGridListWrapper>
  );
};

const gridItems = (listItems: LinkGridListItem[]): ReactElement[] => {
  return listItems.map((listItem: LinkGridListItem) => (
    <ListItemBox cols={[1, 3]} key={listItem.title}>
      <ListItemWrapper>
        <LinkWrapper to={listItem.url} title={listItem.title} target="_blank" rel="noreferrer">
          {listItem.component}
        </LinkWrapper>
      </ListItemWrapper>
    </ListItemBox>
  ));
};
